module.exports = {
  linkResolver(doc) {
    if (doc.type === 'home_solution_article' && doc.lang === 'en-ca') {
      return `/home-solutions/${doc.uid}`;
    }

    if (doc.type === 'home_solution_article' && doc.lang === 'fr-ca') {
      return `/fr/solutions-maison/${doc.uid}`;
    }

    return '/';
  },
};
