"use strict";

exports.onRouteUpdate = function () {
  if (process.env.GATSBY_ACTIVE_ENV === "production" && window.ttd_dom_ready) {
    ttd_dom_ready(function () {
      if (typeof TTDUniversalPixelApi === 'function') {
        var universalPixelApi = new TTDUniversalPixelApi();
        universalPixelApi.init("7c99unf", ["mn7921a"], "https://insight.adsrvr.org/track/up");
      }
    });
  }
};