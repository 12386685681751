module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"ROYALE","short_name":"ROYALE","start_url":"/","background_color":"#ffffff","theme_color":"#4c8dcd","display":"browser","icon":"src/assets/images/favicon.png","theme_color_in_head":false,"legacy":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"bcf4c98879372de139f38a08429017a6"},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-gtag/gatsby-browser.js'),
      options: {"plugins":[],"trackingIds":["AW-951001886"],"pluginConfig":{"head":true}},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-tagmanager/gatsby-browser.js'),
      options: {"plugins":[],"id":"GTM-5SVFSFGH","includeInDevelopment":true,"defaultDataLayer":null,"routeChangeEventName":"gatsby-route-change","enableWebVitalsTracking":false,"selfHostedOrigin":"https://www.googletagmanager.com"},
    },{
      plugin: require('../node_modules/gatsby-plugin-facebook-pixel/gatsby-browser.js'),
      options: {"plugins":[],"pixelId":"633380590116575"},
    },{
      plugin: require('../node_modules/gatsby-plugin-nprogress/gatsby-browser.js'),
      options: {"plugins":[],"color":"tomato","showSpinner":false},
    },{
      plugin: require('../node_modules/gatsby-source-prismic-graphql/gatsby-browser.js'),
      options: {"plugins":[],"repositoryName":"royale-homesolutions","defaultLang":"en-us","langs":["en-ca","fr-ca"],"accessToken":"MC5YQWNxanhVQUFQNFBURjRJ.fnJ777-9Eu-_vXdYKe-_ve-_vSTvv73vv70c77-977-977-977-977-9Tu-_ve-_vQ1u77-9T--_ve-_vTHvv71c","previews":true,"pages":[{"type":"Home_solution_article","match":"/home-solutions/:uid","path":"/home-solution","component":"/home/runner/work/IRV-1014-Frontend/IRV-1014-Frontend/src/en/pages/home-solutions/blog.js","sortBy":"meta_firstPublicationDate_ASC","langs":["en-ca"]},{"type":"Home_solution_article","match":"/fr/solutions-maison/:uid","path":"/fr/solution-maison","component":"/home/runner/work/IRV-1014-Frontend/IRV-1014-Frontend/src/fr/pages/home-solutions/blog.js","sortBy":"meta_firstPublicationDate_ASC","langs":["fr-ca"]}],"sharpKeys":[{}]},
    },{
      plugin: require('../plugins/gatsby-ttd/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../plugins/gatsby-pinterest/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
